import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { HasPermission, useProfile } from './hooks/useProfile';
import Leads from './pages/Leads';

import Login from './pages/Login';
import { isAuthenticated } from './services/auth';
//import Me from './pages/Me';
//import ConfigsHome from './pages/configs/';
//import Leads from './pages/Leads';

interface PrivateRouteProps extends RouteProps{
  component: any;
  neededPermission?: string;
}

const PrivateRoute = ({component: Component, neededPermission = "", ...rest} : PrivateRouteProps) => {
  return <Route {...rest} render={props => (
                !isAuthenticated() ? (
                    <Redirect to={{ pathname: '/' , state: "Por favor, acesse sua conta."}}/>
                )
                :(
                    <Component {...props} />
                )
              )
            } 
          />
}

const Routes = (): JSX.Element => {
    return (
      <Switch>
        <Route path="/" exact component={Login} />

        <PrivateRoute path="/home" exact component={Leads} />

        {/* <Route path="/eu" exact component={Me} /> */}

        {/* <PrivateRoute path="/home" exact component={Leads} /> */}

        {/* <PrivateRoute path="/empresas" component={Roles} /> */}
      </Switch>
    );
};
  
export default Routes;