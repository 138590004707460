import { isAuthenticated, login } from "../services/auth";
import { useHistory } from "react-router";

import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Input as ChakraInput, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Heading, Link, Stack, Icon } from "@chakra-ui/react";

import Logo from '../assets/icons/Logo.svg';
import { Img } from "@chakra-ui/image";
import { api } from "../services/api";

import { Mail, Lock } from "react-feather";

import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from "@chakra-ui/react";
import { Alert } from "../components/Alert";
import { useProfile } from "../hooks/useProfile";
import { useEffect, } from "react";
import { Input } from "../components/Forms/Inputs/Input";

interface SignInFormData{
    email: string;
    password: string;
    remember?: string;
}

const signInFormSchema = yup.object().shape({
    email: yup.string().required('E-mail Obrigatório!').email('E-mail inválido!'),
    password: yup.string().required('Senha Obrigatória'),
    remember: yup.string()
});

export default function Login(){
    const {profile} = useProfile();
    const history = useHistory();
    const { loadProfile } = useProfile();
    const toastSignin = useToast();

    const { register, watch, handleSubmit, formState} = useForm<SignInFormData>({
        resolver: yupResolver(signInFormSchema),
    });

    function redirect(){
        if(profile){
            if(Object.keys(profile).length !== 0){
                history.push('/home');
            }
        }
    }

    const handleSignIn = async (signInData : SignInFormData) => {
        try{
            const response = await api.post('/auth/login', signInData);

            login(response.data.access_token, response.data.expires_in);

            loadProfile();
        }catch(error) {
            if(error.response){
                toastError(error.response.data.error);
            }else{
                toastError(error.message);
            }
        }
    }

    const toastError = (error : string) => {
        toastSignin({
            title: "Erro.",
            description: error,
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    }

    useEffect(() => {
        if(isAuthenticated()){
            redirect();
        }
    }, [profile]);

    // useEffect(() => {
    //     if(isAuthenticated()){
    //         redirect();
    //     }
    // }, []);

    //HTML  
    return(
        <Flex w="100vw" h="100vh" align="center" justify="center" flexDir="column">
            <Img src={Logo} maxW="280px"/>

            <Alert/>

            <Stack mt="50" as="form" spacing="24px" w="100%" p="9" maxW={400} bg="white" borderRadius="4" boxShadow="lg" flexDir="column" onSubmit={handleSubmit(handleSignIn)}>
                <Heading>Login</Heading>

                <Input register={register} type="email" name="email" variant="outline" placeholder="E-mail" colorScheme="orange" error={formState.errors.email}/>

                <Input register={register} type="password" name="password" variant="outline" placeholder="Senha" colorScheme="orange" error={formState.errors.password}/>

                <Flex as="div">
                    <Checkbox {...register("remember")} colorScheme="orange" size="md" mr="15" borderRadius="full" fontSize="sm" color="gray.800" value="true">
                        Permanecer conectado
                    </Checkbox>
                </Flex>

                <Button type="submit" colorScheme="orange" h="38px" size="lg" borderRadius="4" isLoading={formState.isSubmitting}>
                    Entrar
                </Button>
                    
            </Stack>

            {/* <Link align="center" mt="30" color="gray.700">Esqueci minha senha</Link> */}
        </Flex>
    )
}