import { Button, ButtonProps } from "@chakra-ui/react";
import Icon from "@chakra-ui/icon";
import { ElementType } from "react";

interface ButtonModelProps extends ButtonProps{
    icon?: ElementType;
    children: string;
}

export function SolidButton({icon, children, ...rest} : ButtonModelProps){

    const boxShadowColor = (rest.colorScheme === 'red' ? "#e53e3e" : (rest.colorScheme === 'green' ? "#38a169" : (rest.colorScheme === 'blue' ? "#002d56" : ( rest.colorScheme === 'orange' ? "#dd6b20": "#222222"))));

    return (
        <Button h="38px" px="6" w="fit-content" fontWeight="regular" leftIcon={icon && <Icon as={icon} stroke="#ffffff" fontSize="16" fill="none" mr="2"/>} variant="solid" fontSize="14" {...rest} _hover={{boxShadow: `0 8px 25px -8px ${boxShadowColor}`}}>
            {children}
        </Button>
    )
}