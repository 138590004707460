import { HStack, Link, Modal, Text, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, Icon } from "@chakra-ui/react";

import { X } from 'react-feather';
import { SolidButton } from "../../components/Buttons/SolidButton";
import { showErrors } from "../../hooks/useErrors";
import { api } from "../../services/api";
import { Lead } from "../../types";

interface ConfirmLeadsRemoveModalProps{
    isOpen: boolean;
    toRemoveLeads: Lead[];
    onRequestClose: () => void;
    afterRemove: () => void;
}

export function ConfirmLeadsRemoveModal( { isOpen, toRemoveLeads, afterRemove, onRequestClose } : ConfirmLeadsRemoveModalProps){
    const toast = useToast();

    const handleRemoveLeads = async () => {
        try{
            toRemoveLeads.map( async (lead:Lead) => {
                await api.delete(`/leads/destroy/${lead.id}`);

                toast({
                    title: "Sucesso",
                    description: `O Lead ${lead.name} foi removido`,
                    status: "success",
                    duration: 12000,
                    isClosable: true,
                });
            })

            onRequestClose();
            afterRemove();
        }catch(error){
            showErrors(error, toast);
        }
    }

    return(
        <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
            <ModalOverlay />
            <ModalContent borderRadius="4">
                <ModalHeader p="10" fontWeight="700" fontSize="2xl">Remover os seguintes leads?</ModalHeader>

                <ModalCloseButton top="10" right="5"/>
                
                <ModalBody pl="10" pr="10">
                    <Stack justifyContent="space-between" maxH="400px" overflowY="auto" borderBottom="1px solid #dfdfdf">
                        {toRemoveLeads && toRemoveLeads.map((lead:Lead) => {
                            return (
                                <HStack key={lead.id}>
                                    <Text>{lead.name}</Text>
                                    <Text>{lead.email}</Text>
                                    <Text>{lead.phone}</Text>
                                </HStack>
                            )
                        })}
                    </Stack>

                    <SolidButton mt="8" onClick={handleRemoveLeads} colorScheme="red" mr="6" color="white" bg="red.400" _hover={{filter: "brightness(90%)"}} rightIcon={<Icon as={X} stroke="#ffffff" fill="none" width="18px" strokeWidth="3px"/>}>
                        Confirmar e Remover
                    </SolidButton>
                </ModalBody>

                <ModalFooter p="10">
                    <Link onClick={onRequestClose} color="gray.700" fontSize="14px">Cancelar</Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}