import { HStack, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast } from "@chakra-ui/react";
import { SolidButton } from "../../components/Buttons/SolidButton";


import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from "../../components/Forms/Inputs/Input";
import { ReactSelect } from "../../components/Forms/Selects/ReactSelect";
import { useErrors } from "../../hooks/useErrors";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { LeadStatus } from "../../types";

interface NewLeadModalProps{
    isOpen: boolean;
    onRequestClose: () => void;
    afterCreate: () => void;
}

interface CreateNewLeadFormData{
    name: string;
    email: string;
    phone: string;
    uf: string;
    city: string;
    status?: LeadStatus;
}

const CreateLeadFormSchema = yup.object().shape({
    name: yup.string().required('Nome do contato é obrigatório.'),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    uf: yup.string().required(),
    city: yup.string().required(),
});

export function NewLeadModal( { isOpen, onRequestClose, afterCreate, } : NewLeadModalProps){
    const history = useHistory();
    const toast = useToast();
    const { showErrors } = useErrors();

    const { register, control, handleSubmit, reset, formState} = useForm<CreateNewLeadFormData>({
        resolver: yupResolver(CreateLeadFormSchema),
    });

    const handleCreateNewLead = async (leadData : CreateNewLeadFormData) => {
        try{
            console.log(leadData);

            await api.post('/leads/store', leadData);

            toast({
                title: "Sucesso",
                description: `O Lead ${leadData.name} foi cadastrado.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            onRequestClose();
            afterCreate();
            reset();
        }catch(error) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const uf = [
        {value: 'AC', label: 'Acre'},
        {value: 'AL', label: 'Alagoas'},
        {value: 'AP', label: 'Amapá'},
        {value: 'AM', label: 'Amazonas'},
        {value: 'BA', label: 'Bahia'},
        {value: 'CE', label: 'Ceará'},
        {value: 'DF', label: 'Distrito Federal'},
        {value: 'ES', label: 'Espírito Santo'},
        {value: 'GO', label: 'Goiás'},
        {value: 'MA', label: 'Maranhão'},
        {value: 'MT', label: 'Mato Grosso'},
        {value: 'MS', label: 'Mato Grosso do Sul'},
        {value: 'MG', label: 'Minas Gerais'},
        {value: 'PA', label: 'Pará'},
        {value: 'PB', label: 'Paraíba'},
        {value: 'PR', label: 'Paraná'},
        {value: 'PE', label: 'Pernambuco'},
        {value: 'PI', label: 'Piauí'},
        {value: 'RJ', label: 'Rio de Janeiro'},
        {value: 'RN', label: 'Rio Grande do Norte'},
        {value: 'RS', label: 'Rio Grande do Sul'},
        {value: 'RO', label: 'Rondônia'},
        {value: 'RR', label: 'Roraima'},
        {value: 'SC', label: 'Santa Catarina'},
        {value: 'SP', label: 'São Paulo'},
        {value: 'SE', label: 'Sergipe'},
        {value: 'TO', label: 'Tocantins'},
    ];

    return(
        <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
            <ModalOverlay />
            <ModalContent as="form" borderRadius="4" onSubmit={handleSubmit(handleCreateNewLead)}>
                <ModalHeader p="10" fontWeight="700" fontSize="2xl">Cadastrar Contato</ModalHeader>

                <ModalCloseButton top="10" right="5"/>
                
                <ModalBody pl="10" pr="10">
                    <Stack spacing="6">
                        
                        <Input register={register} name="name" type="text" placeholder="Nome" variant="outline" error={formState.errors.name}/>

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="email" type="email" placeholder="E-mail" variant="outline" error={formState.errors.email}/>

                            <Input register={register} name="phone" type="text" placeholder="Telefone" variant="outline" mask="phone" error={formState.errors.phone}/>
                        </HStack>

                        <HStack spacing="4" align="baseline">
                            <ReactSelect name="uf" control={control} options={uf} width="100%" maxWidth="100%" error={formState.errors.uf}/>

                            <Input register={register} name="city" type="text" placeholder="Cidade" variant="outline" error={formState.errors.city}/>
                        </HStack>

                    </Stack>
                </ModalBody>

                <ModalFooter p="10">
                    <SolidButton mr="6" color="white"colorScheme="orange" type="submit" isLoading={formState.isSubmitting}>
                        Cadastrar
                    </SolidButton>

                    <Link onClick={onRequestClose} color="gray.700" fontSize="14px">Cancelar</Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}