import { useQuery } from "react-query";
import { api } from "../services/api";

export interface LeadFilterData{
    search?: string;
}

export const getLeads = async (filter?: LeadFilterData) => {
    if(filter){
        const { data } = await api.get('leads', {
            params: {
              search: filter.search,
            }
        });

        return data;
    }

    const { data } = await api.get('leads');

    return data;
}

export function useLeads(filter: LeadFilterData){
    return useQuery(['users', filter], () => getLeads(filter), {
        staleTime: 1000 * 5 * 60, //fresh
    });
}