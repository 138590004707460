import { Flex, HStack, Img, Link, Icon, Stack, Text, Table, Thead, Tr, Th, Tbody, Td, Checkbox, Badge, Spinner, useToast } from "@chakra-ui/react";
import { Profile } from "../../components/Profile";

import { Settings } from 'react-feather';
import { Board } from "../../components/Board";
import { RemoveButton } from "../../components/Buttons/RemoveButton";
import { SolidButton } from "../../components/Buttons/SolidButton";

import { Plus } from "react-feather";
import { useState } from "react";
import { Lead } from "../../types";
import { idText } from "typescript";
import { ConfirmLeadsRemoveModal } from "./ConfirmLeadsRemoveModal";
import { LeadFilterData, useLeads } from "../../hooks/useLeads";
import { api } from "../../services/api";
import { showErrors } from "../../hooks/useErrors";
import { NewLeadModal } from "./NewLeadModal";
import { UpdateLeadStatusFormData, UpdateLeadStatusModal } from "./UpdateLeadStatusModal";

export default function Leads(){
    const toast = useToast();

    const [filter, setFilter] = useState<LeadFilterData>(() => {
        const data: LeadFilterData = {
            search: '',
        };
        
        return data;
    })

    const leads = useLeads(filter);

    const [isConfirmLeadsRemoveModalOpen, setisConfirmLeadsRemoveModalOpen] = useState(false);
    const [removeLeadData, setRemoveLeadData] = useState<Lead[]>(() => {
        return [];
    });

    function OpenConfirmLeadsRemoveModal(){
        setisConfirmLeadsRemoveModalOpen(true);
    }
    function CloseConfirmLeadsRemoveModal(){
        setisConfirmLeadsRemoveModalOpen(false);
    }

    const handleChangeLeadsSelected = async (event:any) => {
        const leadId = (event?.target.value ? event?.target.value : 0);

        const updatedRemoveLeadData = [...removeLeadData];

        const leadIndex = updatedRemoveLeadData.findIndex(lead => lead.id === parseInt(leadId));
        console.log(leadIndex);

        if(leadIndex >= 0){
            updatedRemoveLeadData.splice(leadIndex, 1);
            setRemoveLeadData(updatedRemoveLeadData);
        }else{
            try{
                const newLead = await api.get(`/leads/${leadId}`).then(response => response.data);

                updatedRemoveLeadData.push(newLead);
                setRemoveLeadData(updatedRemoveLeadData);
            }catch(error) {
                showErrors(error, toast);
            }
        }
    }

    const [isNewLeadModalOpen, setIsNewLeadModalOpen] = useState(false);

    function OpenNewLeadModalOpen(){
        setIsNewLeadModalOpen(true);
    }
    function CloseNewLeadModalOpen(){
        setIsNewLeadModalOpen(false);
    }

    const [isUpdateLeadStatusModalOpen, setIsUpdateLeadStatusModalOpen] = useState(false);
    const [toEditLeadData, setToEditLeadData] = useState(() => {
        const data : UpdateLeadStatusFormData = {
            name: '',
            id: 0,
            status: 0
        };

        return data;
    });

    function OpenUpdateLeadStatusModalOpen(toEditLeadData : UpdateLeadStatusFormData){
        setToEditLeadData(toEditLeadData);
        setIsUpdateLeadStatusModalOpen(true);
    }
    function CloseUpdateLeadStatusModalOpen(){
        setIsUpdateLeadStatusModalOpen(false);
    }

    return (
        <Flex w="100vw" maxW="1200px" m="0 auto" py="10" flexDirection="column">
            <NewLeadModal afterCreate={leads.refetch}  isOpen={isNewLeadModalOpen} onRequestClose={CloseNewLeadModalOpen}/>
            <UpdateLeadStatusModal afterUpdate={leads.refetch} toEditLead={toEditLeadData} isOpen={isUpdateLeadStatusModalOpen} onRequestClose={CloseUpdateLeadStatusModalOpen}/>
            <ConfirmLeadsRemoveModal afterRemove={leads.refetch} toRemoveLeads={removeLeadData} isOpen={isConfirmLeadsRemoveModalOpen} onRequestClose={CloseConfirmLeadsRemoveModal}/>

            <HStack as="header" justifyContent="space-between" mb="28">
                <Img width="100%" maxW="260px" src="./logo.svg" alt="Quero Luz - Energia solar ao seu alcance"/>

                <HStack spacing="10">
                    <Profile />

                    {/* <Link href="configurações" fontSize="sm" color="gray.800">
                        <Icon as={Settings} stroke="#4e4b66" fill="none" mr="10px"/>
                    </Link> */}
                </HStack>
            </HStack>

            <Stack>
                <HStack justifyContent="space-between">
                    <Text fontWeight="semibold" color="gray.700">Lista de Simulados</Text>
                </HStack>

                <Board>
                    <HStack mb="6" justifyContent="space-between">
                        <SolidButton colorScheme="orange" h="38px" size="md" borderRadius="4" icon={Plus} onClick={OpenNewLeadModalOpen}>
                            Adicionar
                        </SolidButton>

                        <RemoveButton onClick={OpenConfirmLeadsRemoveModal}/>
                    </HStack>

                {   leads.isLoading ? (
                            <Flex justify="center">
                                <Spinner/>
                            </Flex>
                        ) : ( leads.isError ? (
                            <Flex justify="center" mt="4" mb="4">
                                <Text>Erro listar os contatos</Text>
                            </Flex>
                        ) : (leads.data.length === 0) && (
                            <Flex justify="center">
                                <Text>Nenhuma contato encontrado.</Text>
                            </Flex>
                        ) ) 
                    }

                    {/* Tabela */}
                    {(!leads.isLoading && !leads.error && (leads.data.length !== 0)) && ( 
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>
                                        {/* <Checkbox colorScheme="orange" size="md" mr="15" borderRadius="full" fontSize="sm" color="gray.800" value="0"></Checkbox> */}
                                    </Th>
                                    <Th>Nome</Th>
                                    <Th>E-mail</Th>
                                    <Th>Telefone</Th>
                                    <Th>Estado</Th>
                                    <Th>Cidade</Th>
                                    <Th>Status</Th>
                                    <Th>Quando</Th>
                                    <Th>Origem</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {leads.data.map((lead:Lead) => {
                                    const statusColor = lead.status.id === 1 ? 'black' : (lead.status.id === 2 ? 'blue' : (lead.status.id === 3 ? 'purple' : (lead.status.id === 4 ? 'red' : 'green')));
                                    return (
                                        <Tr key={lead.id}>
                                            <Td>
                                                <Checkbox onChange={handleChangeLeadsSelected} colorScheme="orange" size="md" mr="15" borderRadius="full" fontSize="sm" color="gray.800" value={lead.id}></Checkbox>
                                            </Td>
                                            <Td fontSize="12px">{lead.name}</Td>
                                            <Td fontSize="12px">{lead.email}</Td>
                                            <Td fontSize="12px">{lead.phone}</Td>
                                            <Td fontSize="12px">{lead.uf}</Td>
                                            <Td fontSize="12px">{lead.city}</Td>
                                            <Td fontSize="12px">
                                                <Badge colorScheme={statusColor} cursor="pointer" onClick={() => OpenUpdateLeadStatusModalOpen({name: lead.name, id: lead.id, status: lead.status.id})}>
                                                    {lead.status.name}
                                                    </Badge>
                                            </Td>
                                            <Td fontSize="12px">{lead.created_at}</Td>
                                            <Td fontSize="12px">{lead.origin}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    )}
                </Board>
            </Stack>
        </Flex>
    )
}