import { Avatar, Button, Flex, Icon, Link, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useState } from "react";

// import { useProfile } from "../../hooks/useProfile";
import { LogoutModal } from "./LogoutModal";

import { User, LogOut } from 'react-feather';

export function Profile(){
    //const { profile } = useProfile();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    function OpenLogoutModal(){
        setIsLogoutModalOpen(true);
    }

    function CloseLogoutModal(){
        setIsLogoutModalOpen(false);
    }

    return(
        <Flex ml="auto" title="Ver informações ou sair" cursor="pointer">

            <Menu placement="bottom-end">
                <MenuButton display="flex">

                    <Flex>
                        {/* PROFILE ITEM */}
                        <Link href="/eu" mr="4" textAlign="right" _hover={{textDecoration: "none"}}>
                            <Text fontWeight="600" fontSize="sm" color="gray.700">Mateus Berlitz</Text>
                            <Text fontSize="small" color="gray.700">
                                Programador
                            </Text>
                        </Link>

                        <Link href="/eu" display="flex" borderRadius="full">
                            <Avatar size="md" h="42px" w="42px" name="Mateus Berlitz" src="" /> {/* `${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_STORAGE : process.env.REACT_APP_API_LOCAL_STORAGE}${profile.image}` : ""}/> */}
                        </Link>
                    </Flex>

                </MenuButton>

                <MenuList boxShadow="md">
                    <MenuGroup title="Perfil">
                        <MenuItem fontSize="sm"><Icon as={User} fontSize="16" stroke="#625f6e" fill="none" mr="2"/>Meu perfil</MenuItem>
                        <MenuItem fontSize="sm" onClick={OpenLogoutModal}><Icon as={LogOut} fontSize="16" stroke="#625f6e" fill="none" mr="2"/>Desconectar</MenuItem>
                    </MenuGroup>
                </MenuList>
            </Menu>

            <LogoutModal isOpen={isLogoutModalOpen} onRequestClose={CloseLogoutModal}/>
        </Flex>
    );
}