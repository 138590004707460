import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { isAuthenticated } from "../../services/auth";
import { encodePermissions, simplifyPermissions, decodePermissions } from "../../services/permissionsSecurity";
import { getMe } from "./useMe";
import { getPermissions } from "./useProfilePermissions";

interface ProfileProviderProps{
    children: ReactNode;
}

interface Profile{
    id: number,
    name: string;
    last_name: string;
    email: string;
    image: string;
    cpf: string;
    email_verified_at: Date;
    phone: string;
    role: {
        id: number,
        name: string,
        desk_id: number,
        created_at: Date;
        updated_at: Date;
    };
    created_at: Date;
    updated_at: Date;
}

// interface Permission{
//     id: number;
//     name: string;
//     created_at: Date;
//     updated_at: Date;
// }

interface SimplePermission{
    name: string;
}

interface ProfileContextData{
    profile?: Profile;
    permissions?: SimplePermission[];
    loadProfile: () => void;
}

const ProfileContext = createContext<ProfileContextData>({} as ProfileContextData);

export function ProfileProvider({ children } : ProfileProviderProps){
    const [profile, setProfile] = useState<Profile>(() => {
        const storagedProfile = localStorage.getItem('@queroluz/profile');
    
        if (storagedProfile) {
          return JSON.parse(storagedProfile);
        }
    
        return {};
    });



    //PREFER STATE OF PROFILE
    const previousProfileRef = useRef<Profile>();

    useEffect(() => {
        previousProfileRef.current = profile;
    });

    const profilePreviousValue = previousProfileRef.current ?? profile;

    useEffect(() => {
        if(profilePreviousValue !== profile){
            localStorage.setItem('@queroluz/profile', JSON.stringify(profile));
        }
    }, [profile, profilePreviousValue]);



    //const {changeCompany} = useWorkingCompany();
    //LOADERS
    const loadProfile = async () => {
        const loadedProfile = await getMe();

        setProfile(loadedProfile);
    }

    if(isAuthenticated()){
        if(!profile.name){
            loadProfile();
        }
    }

    return(
        <ProfileContext.Provider value={{profile, loadProfile}}>
            {children}
        </ProfileContext.Provider>
    )
}

export function HasPermission(permissions: SimplePermission[] | undefined, neededPermission : string,){

    if(permissions){
        if(neededPermission === ""){
            return true;
        }

        if(permissions.find(permission => permission.name === neededPermission)){
            return true;
        }
    }

    return false;
}

export const useProfile = () => useContext(ProfileContext);