import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Control, Controller, FieldError, UseFormRegister } from "react-hook-form";
import Select from "react-select/";

interface SelectOption{
    value: string,
    label: string,
}

interface ReactSelectProps{
    name: string;
    options: SelectOption[];
    register?: UseFormRegister<any>;
    error?: FieldError;
    control?: any;

    value?: string;
    variant?: string;

    width?: string;
    marginBottom?: string;
    maxWidth?: string
}

export function ReactSelect({name, register, control, value = "", variant = 'outline', error, options, width, marginBottom, maxWidth, ...rest} : ReactSelectProps){
    const customStyles = {
        container: (styles:any) => ({ 
            ...styles,
            width: width,
            marginBottom: marginBottom,
            maxWidth: maxWidth,
        }),
        input: (styles:any) => ({ 
            ...styles ,
            fontSize: "14px"
        }),
        option: (provided:any, state:any) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#ff8e2e' : 'white',
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          fontSize: '14px',
          ':hover': {
            backgroundColor: !state.isSelected ? "#ffefe1" : "#ff8e2e",
            color: !state.isSelected ? "#ff8e2e" : "#ffffff"
          },
        }),
        control: (styles: any, state: any) => ({ 
            ...styles, 
            backgroundColor: 'white' , 
            borderColor: state.isFocused && variant === 'outline' ? "#ff8e2e" : (variant === 'outline' ? "#dddddd" : '#fff'),
            // ':focus': {
                boxShadow: 0,//state.isFocused ? "0 0 0 1px #ff8e2e" : ""
            // },
            ':hover': {
                borderColor: state.isFocused && variant === 'outline' ? "#ff8e2e" : (variant === 'outline' ? "#dddddd" : '#fff'),
            },
        }),
        singleValue: (provided:any, state:any) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          const color = "#6e6b7b";
      
          return { ...provided, opacity, transition, color, fontSize: '14px', };
        }
      }

    //const defaultValue = value;
    

    const [controlledValue, setControlledValue] = useState("");

    useEffect(() => {
        setControlledValue(value);
    }, [value]);


    return control ? (
        <FormControl pos="relative" isInvalid={!!error}>
            <Controller
                name={name}
                control={control}
                defaultValue={controlledValue}
                render={({ field: {ref, onChange, value, ...select} }) => 
                    <Select {...select} inputRef={ref} options={options} styles={customStyles} value={options.find(c => c.value === controlledValue)} onChange={val => {onChange(val ? val.value : ""); setControlledValue(val ? val.value : "");}} error={error} {...rest}/>
                }
            />
            {/* <Select options={options} styles={customStyles}/> */}

            { !!error && (
                <FormErrorMessage>
                    {error.message}
                </FormErrorMessage>
            )}
        </FormControl>
    ) : (

        <Select options={options} styles={customStyles} error={error} {...rest}/>

    );
}