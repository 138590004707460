import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ProfileProvider } from './hooks/useProfile';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {

  return (
    
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                      <ProfileProvider>
                              <Routes />
                      </ProfileProvider>
            </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  
);
}

export default App;
