import { HStack, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast } from "@chakra-ui/react";
import { SolidButton } from "../../components/Buttons/SolidButton";


import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from "../../components/Forms/Inputs/Input";
import { ReactSelect } from "../../components/Forms/Selects/ReactSelect";
import { useErrors } from "../../hooks/useErrors";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { LeadStatus } from "../../types";

interface UpdateLeadStatusModalProps{
    isOpen: boolean;
    onRequestClose: () => void;
    afterUpdate: () => void;
    toEditLead: UpdateLeadStatusFormData;
}

export interface UpdateLeadStatusFormData{
    id: number;
    name: string;
    status?: number;
}

const CreateLeadFormSchema = yup.object().shape({
    status: yup.string().required(),
});

export function UpdateLeadStatusModal( { toEditLead, isOpen, onRequestClose, afterUpdate, } : UpdateLeadStatusModalProps){
    const history = useHistory();
    const toast = useToast();
    const { showErrors } = useErrors();

    const { register, control, handleSubmit, reset, formState} = useForm<UpdateLeadStatusFormData>({
        resolver: yupResolver(CreateLeadFormSchema),
    });

    const handleUpdateLeadStatus = async (leadData : UpdateLeadStatusFormData) => {
        try{
            await api.post(`/leads/edit/${toEditLead.id}`, leadData);

            toast({
                title: "Sucesso",
                description: `O Lead ${leadData.name} foi alterado.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            onRequestClose();
            afterUpdate();
            reset();
        }catch(error) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const statuses = [
        {value: '1', label: 'Não contatado'},
        {value: '2', label: 'Contatado'},
        {value: '3', label: 'Aquecido'},
        {value: '4', label: 'Cancelado'},
        {value: '5', label: 'Fechado'},
    ];

    return(
        <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
            <ModalOverlay />
            <ModalContent as="form" borderRadius="4" onSubmit={handleSubmit(handleUpdateLeadStatus)}>
                <ModalHeader p="10" fontWeight="700" fontSize="2xl">Alterar {toEditLead.name}</ModalHeader>

                <ModalCloseButton top="10" right="5"/>
                
                <ModalBody pl="10" pr="10">
                    <Stack spacing="6">
                        <ReactSelect name="status" control={control} value={toEditLead.status?.toString()} options={statuses} width="100%" maxWidth="100%" error={formState.errors.status}/>
                    </Stack>
                </ModalBody>

                <ModalFooter p="10">
                    <SolidButton mr="6" color="white"colorScheme="orange" type="submit" isLoading={formState.isSubmitting}>
                        Atualizar
                    </SolidButton>

                    <Link onClick={onRequestClose} color="gray.700" fontSize="14px">Cancelar</Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}